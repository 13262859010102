/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {

  /** rona **/
  --ion-background-color: #346768;
  --ion-background-color-rgb: 52, 103, 104;
  --ion-text-color: #ffca00;
  --ion-text-color-rgb: 255, 202, 0;
  --ion-toolbar-color: #ffca00;
}

ion-fab-button {
  --background: #ffca00;
  --color: #346768;
}

ion-col {
  color: #ffca00;
}

ion-card-title {
  color: #ffca00;
}

ion-card-subtitle {
  color: #ffca00;
}

ion-button {
  color: #ffca00;
  --background: #113738;
}
