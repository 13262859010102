.login-container-parent {
    --ion-background-color: #346768;
}

.login-container {
    text-align: center;
    background-color: #346768;
}

.login-container img {
    height: 320px;
}

.login-info {
    position: absolute;
    bottom: 1em;
    text-align: center;
}

.login-info a {
    text-decoration: underline;
    color: #ffca00;
}

#login-footer {
    text-align: center;
}

#login-footer img {
    padding-top: 6px;
    height: 64px;
}

#login-footer img:hover {
    cursor: pointer;
}