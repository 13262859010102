ion-fab-button#fab-peoples {
    --background: #ffca00;
    --color: #346768;
}

ion-fab-button[data-desc] {
    position: relative;
    --background: #ffca00;
    --color: #346768;
}

ion-fab-button[data-desc]::after {
    position: absolute;
    content: attr(data-desc);
    z-index: 1;
    right: 50px;
    bottom: 4px;
    background-color: #346768;
    color: #ffca00;
    padding: 9px;
    border-radius: 15px;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
}