ion-col.person-grid-header {
    font-size: large;
    font-weight: bold;
    text-align: center;
    border: 1px solid;
    background-color: black;
}

ion-col.person-grid-item {
    border: 1px solid;
}