ion-menu ion-list-header, ion-menu ion-note {
  padding-left: 10px;
}

ion-menu ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu ion-list#inbox-list ion-list-header {
  font-size: x-large;
}

ion-menu ion-list#inbox-list ion-item {
  --padding-start: 40px;
  --padding-end: 10px;
  border-radius: 4px;
  font-size: smaller;
}

ion-note {
  display: inline-block;
  color: var(--ion-color-medium-shade);
  padding-bottom: 10px;
}
